import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Link, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel";
const defaultProps = {
	"background": "linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)",
	"padding": "36px 0 36px 0",
	"quarkly-title": "Header",
	"box-shadow": "--m"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"width": "30%",
			"sm-width": "50%",
			"display": "flex",
			"align-items": "center",
			"empty-min-width": "80px",
			"empty-min-height": "64px",
			"empty-border-color": "LightGray"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/",
			"font": "normal 600 18px/1.5 --fontFamily-googleInter",
			"color": "--white",
			"margin": "0px 20px 0px 20px",
			"md-margin": "0px 0px 13px 0px",
			"md-font": "--lead",
			"text-decoration-line": "initial",
			"md-color": "--light",
			"children": "Arabanızın En İyi Arkadaşı"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"width": "70%",
			"sm-width": "50%",
			"align-items": "center",
			"empty-min-width": "80px",
			"empty-border-style": "solid",
			"empty-border-color": "LightGray",
			"display": "flex"
		}
	},
	"quarklycommunityKitMobileSidePanel": {
		"kind": "QuarklycommunityKitMobileSidePanel",
		"props": {
			"md-z-index": "9",
			"onloadShow": false
		}
	},
	"quarklycommunityKitMobileSidePanelOverride": {
		"kind": "Override",
		"props": {
			"slot": "Content",
			"align-items": "center",
			"padding": "16px 0px 16px 16px",
			"md-background": "--color-light",
			"md-z-index": "9",
			"md-justify-content": "flex-start",
			"background": "rgba(255, 255, 255, 0)",
			"justify-content": "center"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Children",
			"justify-content": "center",
			"display": "flex",
			"align-items": "center"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride2": {
		"kind": "Override",
		"props": {
			"slot": "Button Text",
			"md-display": "none"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride3": {
		"kind": "Override",
		"props": {
			"slot": "Button Icon",
			"md-color": "--white",
			"md-font": "36px sans-serif"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride4": {
		"kind": "Override",
		"props": {
			"slot": "Cross",
			"md-color": "--dark",
			"md-font": "36px sans-serif"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride5": {
		"kind": "Override",
		"props": {
			"slot": "Wrapper",
			"md-z-index": "9"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"justify-content": "flex-end",
			"md-flex-direction": "column",
			"md-margin": "40px 0px 13px 0px",
			"md-font": "--headline4"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "/",
			"font": "normal 600 18px/1.5 --fontFamily-googleInter",
			"text-decoration-line": "initial",
			"color": "--white",
			"margin": "0px 20px 0px 20px",
			"md-margin": "0px 0px 13px 0px",
			"md-font": "--lead",
			"md-color": "--indigo",
			"children": "Ana sayfa"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"transition": "opacity 0.3s ease 0s",
			"md-margin": "0px 0px 13px 0px",
			"href": "/about",
			"font": "--base",
			"text-decoration-line": "initial",
			"hover-opacity": "1",
			"md-font": "--lead",
			"md-color": "--indigo",
			"color": "--grey",
			"margin": "0px 20px 0px 20px",
			"opacity": "0.8",
			"children": "Hizmetler"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"color": "--grey",
			"margin": "0px 20px 0px 20px",
			"transition": "opacity 0.3s ease 0s",
			"md-margin": "0px 0px 13px 0px",
			"md-font": "--lead",
			"href": "/contact",
			"font": "--base",
			"text-decoration-line": "initial",
			"opacity": "0.8",
			"hover-opacity": "1",
			"md-color": "--indigo",
			"children": "Kişiler"
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" />
		<Box {...override("box")}>
			<Link {...override("link")} />
		</Box>
		<Box {...override("box1")}>
			<QuarklycommunityKitMobileSidePanel {...override("quarklycommunityKitMobileSidePanel")}>
				<Override {...override("quarklycommunityKitMobileSidePanelOverride")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride1")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride2")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride3")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride4")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride5")} />
				<Box {...override("box2")}>
					<Link {...override("link1")} />
					<Link {...override("link2")} />
					<Link {...override("link3")} />
				</Box>
			</QuarklycommunityKitMobileSidePanel>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;