import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"background": "linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)",
	"quarkly-title": "HeroBlock",
	"md-padding": "36px 0 60px 0",
	"padding": "80px 0 80px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"empty-min-width": "80px",
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"empty-border-color": "LightGray",
			"flex-direction": "column",
			"display": "flex",
			"align-items": "flex-start",
			"lg-padding": "0px 90px 0px 0px",
			"md-width": "100%",
			"justify-content": "center",
			"padding": "0px 180px 0px 0px",
			"md-padding": "0px 0px 0px 0px",
			"width": "65%"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"lg-font": "normal 400 14px/1.5 \"Inter\", sans-serif",
			"margin": "0px 0px 8px 0px",
			"color": "--grey",
			"lg-text-align": "left",
			"font": "--base",
			"text-transform": "uppercase",
			"lg-margin": "0px 0px 8px 0px",
			"sm-font": "normal 500 16px/1.6 \"Inter\", sans-serif",
			"children": "VividSpaces'e hoş geldiniz!"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"lg-text-align": "left",
			"lg-font": "normal 700 40px/1.2 \"Inter\", sans-serif",
			"md-font": "normal 700 28px/1.2 \"Inter\", sans-serif",
			"margin": "0px 0px 0 0px",
			"font": "--headline1",
			"color": "--white",
			"sm-font": "normal 500 30px/1.2 \"Inter\", sans-serif",
			"children": "Sizin Alanınız, Sizin Hikayeniz"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"lg-margin": "32px 0px 0px 0px",
			"md-width": "100%",
			"md-margin": "36px 0px 0px 0px",
			"display": "flex",
			"margin": "20px 0px 0px 0px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"sm-margin": "0px 22px 0px 0px",
			"transition": "background-color 0.3s --transitionTimingFunction-easeInOut 0s",
			"href": "#why",
			"text-decoration-line": "initial",
			"sm-font": "normal 500 18px/1.6 \"Inter\", sans-serif",
			"md-font": "normal 500 16px/1.6 \"Inter\", sans-serif",
			"sm-padding": "15px 13px 15px 13px",
			"md-text-align": "center",
			"lg-margin": "0px 32px 0px 0px",
			"padding": "15px 30px 15px 30px",
			"border-radius": "10px",
			"lg-font": "normal 500 18px/1.6 \"Inter\", sans-serif",
			"md-padding": "15px 16px 15px 16px",
			"hover-color": "--primary",
			"color": "--white",
			"margin": "0px 44px 0px 0px",
			"lg-padding": "15px 23px 15px 23px",
			"md-width": "50%",
			"background": "--color-primary",
			"font": "--lead",
			"hover-background": "--color-white",
			"border-width": "2px",
			"border-style": "solid",
			"border-color": "--color-primary",
			"children": "Başlamak"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"padding": "15px 30px 15px 30px",
			"font": "--lead",
			"border-radius": "10px",
			"border-width": "2px",
			"transition": "background-color 0.3s --transitionTimingFunction-easeInOut 0s",
			"lg-font": "normal 500 18px/1.6 \"Inter\", sans-serif",
			"border-style": "solid",
			"md-width": "50%",
			"md-font": "normal 500 16px/1.6 \"Inter\", sans-serif",
			"md-text-align": "center",
			"sm-padding": "15px 13px 15px 13px",
			"text-decoration-line": "initial",
			"color": "--white",
			"background": "rgba(255, 5, 5, 0)",
			"margin": "0px 0px 0px 0px",
			"border-color": "rgba(255, 255, 255, 0.3)",
			"sm-font": "normal 500 18px/1.6 \"Inter\", sans-serif",
			"hover-background": "--color-white",
			"hover-color": "--indigo",
			"href": "/about",
			"lg-padding": "15px 23px 15px 23px",
			"md-padding": "15px 16px 15px 16px",
			"children": "Temasta olmak"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"empty-border-style": "solid",
			"display": "flex",
			"justify-content": "flex-end",
			"empty-border-width": "1px",
			"empty-min-height": "64px",
			"empty-border-color": "LightGray",
			"align-items": "center",
			"md-width": "100%",
			"md-order": "-1",
			"empty-min-width": "80px",
			"width": "35%"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"lg-max-height": "400px",
			"width": "100%",
			"md-margin": "0px 0px 30px 0px",
			"lg-position": "relative",
			"lg-left": "-5px",
			"max-width": "100%",
			"src": "https://uploads.quarkly.io/661f820a8d4a0c00202e765c/images/construction_02.png?v=2024-04-17T08:45:23.536Z",
			"max-height": "500px",
			"object-fit": "cover"
		}
	}
};

const Hero = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			position="relative"
			z-index="9"
			md-flex-wrap="wrap"
			md-z-index="7"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Box {...override("box1")}>
				<Link {...override("link")} />
				<Link {...override("link1")} />
			</Box>
		</Box>
		<Box {...override("box2")}>
			<Image {...override("image")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Hero, { ...Section,
	defaultProps,
	overrides
});
export default Hero;