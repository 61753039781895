import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import Hero from "./Hero";
const defaultProps = {};
const overrides = {};

const Hero12 = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Hero {...rest}>
		<Override slot="text1" />
		<Override slot="text" />
		<Override slot="link1" />
		<Override slot="link" />
		<Override slot="image" />
		<Override slot="box" />
		<Override slot="box2" />
		{children}
	</Hero>;
};

Object.assign(Hero12, { ...Hero,
	defaultProps,
	overrides
});
export default Hero12;